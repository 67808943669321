//Lato
@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Regular/Lato-Regular.eot');
	src: url('../fonts/Lato/Lato-Regular/Lato-Regular.eot?#iefix') format('embedded-opentype'), 
		url('../fonts/Lato/Lato-Regular/Lato-Regular.woff2') format('woff2'), 
		url('../fonts/Lato/Lato-Regular/Lato-Regular.woff') format('woff'), 
		url('../fonts/Lato/Lato-Regular/Lato-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Italic/Lato-Italic.eot');
	src: url('../fonts/Lato/Lato-Italic/Lato-Italic.eot?#iefix') format('embedded-opentype'), 
		url('../fonts/Lato/Lato-Italic/Lato-Italic.woff2') format('woff2'),  
		url('../fonts/Lato/Lato-Italic/Lato-Italic.woff') format('woff'),  
		url('../fonts/Lato/Lato-Italic/Lato-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Bold/Lato-Bold.eot');
	src: url('../fonts/Lato/Lato-Bold/Lato-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Bold/Lato-Bold.woff2') format('woff2'), 
		url('../fonts/Lato/Lato-Bold/Lato-Bold.woff') format('woff'), 
		url('../fonts/Lato/Lato-Bold/Lato-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-BoldItalic/Lato-BoldItalic.eot');
	src: url('../fonts/Lato/Lato-BoldItalic/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), 
		url('../fonts/Lato/Lato-BoldItalic/Lato-BoldItalic.woff2') format('woff2'), 
		url('../fonts/Lato/Lato-BoldItalic/Lato-BoldItalic.woff') format('woff'), 
		url('../fonts/Lato/Lato-BoldItalic/Lato-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}


//Lora
@font-face {
	font-family: 'Lora';
	src: url('../fonts/Lora/Lora-Regular/Lora-Regular.eot');
	src: url('../fonts/Lora/Lora-Regular/Lora-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Lora/Lora-Regular/Lora-Regular.woff') format('woff'), url('../fonts/Lora/Lora-Regular/Lora-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Lora';
	src: url('../fonts/Lora/Lora-Italic/Lora-Italic.eot');
	src: url('../fonts/Lora/Lora-Italic/Lora-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/Lora/Lora-Italic/Lora-Italic.woff') format('woff'), url('../fonts/Lora/Lora-Italic/Lora-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Lora';
	src: url('../fonts/Lora/Lora-Bold/Lora-Bold.eot');
	src: url('../fonts/Lora/Lora-Bold/Lora-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Lora/Lora-Bold/Lora-Bold.woff') format('woff'), url('../fonts/Lora/Lora-Bold/Lora-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lora';
	src: url('../fonts/Lora/Lora-BoldItalic/Lora-BoldItalic.eot');
	src: url('../fonts/Lora/Lora-BoldItalic/Lora-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Lora/Lora-BoldItalic/Lora-BoldItalic.woff') format('woff'), url('../fonts/Lora/Lora-BoldItalic/Lora-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}
