.common_header,
.blur_header {
  font-size: 20px;

  @media (max-width: $md) {
    font-size: 18px;
  }

  @media (max-width: $sm) {
    font-size: 16px;
  }

  h1 {
    font-family: $Lora;
    font-weight: 700;
    font-size: 3.157em;
    line-height: 1.2;

    @media (max-width: $lg) {
      font-size: 2.441em;
    }

    @media (max-width: $sm) {
      font-size: 2.074em;
    }

    @media (max-width: $xs) {
      font-size: 1.602em;
    }
  }
}

.common_header {
  position: relative;
  margin: (3.157em * 1.414) 0 (3.157em / 2);

  @media (max-width: $lg) {
    margin: (2.441em * 1.414) 0 (2.441em / 2);
  }

  @media (max-width: $sm) {
    margin: (2.074em * 1.414) 0 (2.074em / 2);
  }

  @media (max-width: $xs) {
    margin: (1.602em * 1.414) 0 (1.602em / 2);
  }

  .link_back {
    margin: (-3.157em / 1.414) 0 (3.157em / 1.414);

    @media (max-width: $lg) {
      margin: (-2.441em / 1.414) 0 (2.441em / 1.414);
    }

    @media (max-width: $sm) {
      margin: (-1.414em / 2) 0 1.414em;
    }

    a {
      display: inline-block;
      color: #aaa;
      text-decoration: none;

      span {
        font-family: $Lato;
        font-weight: 700;
        font-style: italic;
      }

      &:hover {
        span {
          color: $lightpurple;
        }
      }
    }

    i {
      margin-right: 0.5em;
      color: $purple;
      font-size: 24px;

      @media (max-width: $md) {
        font-size: 20px;
      }
    }
  }

  &-date {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;
    color: #aaa;
    line-height: 24px;

    @media (max-width: $md) {
      line-height: 20px;
    }
  }

  h1 {
    color: #222;
  }

  &-quote {
    font-size: 23px;
    font-style: italic;
    font-family: $Lora;
    color: #666;
    text-align: right;
    line-height: 1.45;
    margin-top: 1.414em;

    @media (max-width: $lg) {
      font-size: 19px;
    }

    @media (max-width: $sm) {
      font-size: 16px;
    }
  }
}

.blur_header {
  height: 300px;
  position: relative;
  margin-bottom: (3.157em / 2);
  overflow: hidden;
  color: #fff;
  background-color: #222;

  @media (max-width: $lg) {
    height: auto;
    margin-bottom: (2.441em / 2);
  }

  @media (max-width: $sm) {
    background: none;
    margin: (2.074em * 1.414) 0 (2.074em / 2);
  }

  @media (max-width: $xs) {
    margin: (1.602em * 1.414) 0 (1.602em / 2);
  }

  &-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    filter: blur(10px);

    @media (max-width: $sm) {
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#000, 0.2);
    }
  }

  &-container {
    position: absolute;
    z-index: 1;
    bottom: 4rem;
    left: 0;
    right: 0;

    @media (max-width: $lg) {
      position: relative;
      bottom: 0;
    }

    h1 {
      text-shadow: 1px 3px 5px rgba($lightpurple, 0.6);

      @media (max-width: $lg) {
        padding: 1em 0;
      }

      @media (max-width: $sm) {
        text-shadow: none;
        color: #222;
        padding: 0;
      }
    }
  }
}

.text {
  color: #222;
  font-size: 20px;
  line-height: 1.45;

  @media (max-width: $md) {
    font-size: 18px;
  }

  @media (max-width: $sm) {
    font-size: 16px;
  }

  h2,
  h3 {
    font-family: $Lora;
    font-weight: 700;
    margin: 1.414em 0 0.5em;
    line-height: 1.2;
  }

  h2 {
    font-size: 2.369em;
    color: #333;

    @media (max-width: $lg) {
      font-size: 1.953em;
    }

    @media (max-width: $sm) {
      font-size: 1.728em;
    }

    @media (max-width: $xs) {
      font-size: 1.424em;
    }
  }

  h3 {
    font-size: 1.333em;
    color: #333;

    @media (max-width: $lg) {
      font-size: 1.25em;
    }

    @media (max-width: $sm) {
      font-size: 1.2em;
    }

    @media (max-width: $xs) {
      font-size: 1.125em;
    }
  }

  img {
    height: auto !important;
    width: auto !important;
    max-width: 100%;
    display: block;
    margin: 2em 0;

    @media (max-width: $sm) {
      margin: 1.414em 0;
    }
  }

  .youtube-embed-wrapper {
    display: block;
    margin: 2em 0;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  a {
    color: $green;
    font-weight: 700;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:active {
      color: darken($green, 10%);
    }
  }

  p {
    margin-bottom: 1em;
  }

  li {
    margin: 1em 0;
    padding-left: 2em;
    position: relative;

    @media (max-width: $sm) {
      padding-left: 1.5rem;
    }
  }

  ol {
    counter-reset: ol;

    & > li {
      counter-increment: ol;

      &:before {
        content: counter(ol)'.';
        font-weight: 700;
        line-height: 1;
        position: absolute;
        top: 0px;
        right: calc(100% - 1em);
        font-size: 1.33em;
        color: $lightpurple;

        @media (max-width: $lg) {
          font-size: 1.25em;
        }

        @media (max-width: $sm) {
          font-size: 1.2em;
          top: 2px;
        }

        @media (max-width: $xs) {
          font-size: 1.125em;
        }
      }
    }
  }

  ul {
    & > li {
      &:before {
        content: "";
        position: absolute;
        top: calc(0.7em - 4px);
        left: 0.75em;
        height: 10px;
        width: 10px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: $lightpurple;

        @media (max-width: $sm) {
          left: 0;
        }
      }
    }
  }
}

.text {
  .phones {
    a {
      font-weight: 400;
      text-decoration: none;
      color: inherit;
    }
  }

  .emails {
    a {
      text-decoration: none;
    }
  }
}
