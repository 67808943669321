.contact {
	.wrapper {
		padding-bottom: 0;
	}

	&-container {
		@include clearfix;
		margin-bottom: 2rem;
	}

	&-col {
		box-sizing: border-box;
		font-size: 16px;
		float: left;
		width: 50%;


		@media (max-width: $lg) and (min-width: $md+1) {
			&.phones {
				width: 60%;
			}

			&.emails {
				width: 40%;
			}
		}

		@media (max-width: $md) {
			float: none;
			width: 100%;
		}

		h2 {
			@media (min-width: $md+1) {
				margin-top: 0;
			}
		}
	}

	h2 {
		i {
			margin-left: 0.25em;
			font-size: 0.875em;
			color: $lightpurple;
		}
	}

	.phones {
		&-col {
			display: inline-block;
			vertical-align: top;
			margin-right: 4rem;

			@media (max-width: $xs) {
				display: block;
			}

			h3 {
				@media (min-width: $xs+1) {
					margin-top: 0;
				}
			}

		}

		li {
			padding-left: 0;

			&:before {
				display: none;
			}
		}
	}

	.addres {
		width: 100%;
		clear: both;
		float: left;

		h2 {
			margin-top: 3rem;
		}
	}

	&-map {
		height: 600px;
		background-color: rgba($lightpurple, 0.25);
	}
}
