.news {
	&--narrow {
		max-width: 990px;
		margin: 0 auto;
	}

	&-item {
		font-size: 20px;
		margin: 1.77em 0 2em;
		background: #fff;
		border: 2px solid $lightpurple;
		box-shadow: 0 1px 10px 0 rgba($lightpurple, 0.5);
		border-radius: 6px;
		padding: 2rem 3rem;

		@media (max-width: $md) {
			padding: 1.5rem 2rem;
			font-size: 18px;
		}

		@media (max-width: $sm) {
			padding: 1.5rem 1rem;
			font-size: 16px;
		}

		&:first-child {
			margin-top: 2em;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.news-item-header {
			font-family: $Lora;
			font-weight: 700;
			font-size: 1.777em;
			margin: 0 0 0.5em;
			color: #333;

			@media (max-width: $lg) {
				font-size: 1.563em;
			}

			@media (max-width: $sm) {
				font-size: 1.44em;
			}

			@media (max-width: $xs) {
				font-size: 1.266em;
			}

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					color: $lightpurple;
				}
			}
		}

		& > *:last-child {
			margin-bottom: 0;
		}

		// Wide item
		&-container {
			display: table-row;

			& > * {
				display: table-cell;
				vertical-align: middle;
				@media (max-width: $sm) {
					display: block;
				}
			}
		}

		&-image {
			padding-right: 3rem;

			@media (max-width: $md) {
				padding-right: 2rem;
			}
			
			@media (max-width: $sm) {
				padding: 0.5rem 0 1.44rem;
			}
			
			@media (max-width: $xs) {
				text-align: center;
				padding-bottom: 1rem;
			}

			&:hover {
				& + .news-item-description .news-item-header {
					color: $lightpurple;
				}

				img {
					//	filter: none;
				}
			}

			img {
				width: 150px;
				max-width: none;
				//	filter: grayscale(100%);
				//	transition: 0.2s ease;
			}
		}

		&-description {
			& > *:last-child {
				margin-bottom: 0;
			}
		}

		&-date {
			margin-bottom: 0.5em;
			font-weight: 700;
			color: #999;
			font-size: 0.75em;

			@media (max-width: $lg) {
				font-size: 0.8em;
			}

			@media (max-width: $sm) {
				font-size: 0.833em;
			}

			@media (max-width: $xs) {
				font-size: 0.889em;
			}
		}
	}
}
