.vacancies {
	&-filter {
		.search {
			@include clearfix;
			margin-bottom: 3rem;
			display: table;
			width: 100%;

			@media (max-width: $sm) {
				margin-bottom: 2rem;
			}

			@media (max-width: $xs) {
				margin-bottom: 1.75rem;
			}

			.form-input {
				width: 100%;
				//float: left;
				padding: 0;
				margin-top: 0;
				box-sizing: border-box;
				display: table-cell;
				vertical-align: top;

				@media (max-width: $sm) {
					width: 100%;
					margin-bottom: 1rem;
				}

				input {
					@media (max-width: $sm) {
						padding: 9px 1rem;
					}

					@media (max-width: $xs) {
						padding: 9px 0.75rem;
						font-size: 15px;
					}

					@media (max-width: 350px) {
						font-size: 14px;
					}
				}
			}

			.btn {
				margin: 0;
				margin-left: 1rem;
				width: 300px;
				//float: right;
				box-sizing: border-box;
				min-width: 0;
				display: table-cell;
				vertical-align: top;
				padding: 1.2rem 1.5em;

				@media (max-width: $lg) {
					width: 250px;
				}

				@media (max-width: $md) {
					width: 200px;
					font-size: 20px;
					padding: 0.75em 1.25em;
				}

				@media (max-width: $sm) {
					font-size: 18px;
					width: 175px;
				}

				@media (max-width: $xs) {
					width: auto;
					margin-left: 0.5rem;
				}

				span {
					@media (max-width: $xs) {
						display: none;
					}
				}

				i {
					display: none;

					@media (max-width: $xs) {
						display: inline;
					}
				}
			}
		}

		.categories {
			.form-checkbox {
				width: 33.33%;
				float: left;
				margin-bottom: 1rem;

				@media (max-width: $md) {
					width: 50%;
					font-size: 15px;
					margin-bottom: 0.75rem;
				}

				@media (max-width: $sm) {
					font-size: 14px;
					margin-bottom: 0.5rem;
				}

				@media (max-width: $xs) {
					width: 100%;
					margin-bottom: 0.6rem;
				}
			}
		}
	}

	.categories-container {
		@include clearfix;
	}

	&-item {
		font-size: 20px;
		margin: 1.414em 0 1.77em;
		background: #fff;
		border: 1px solid $purple;
		box-shadow: 0 1px 10px 0 rgba($lightpurple, 0.5);
		border-radius: 6px;
		position: relative;
		display: flex;

		@media (max-width: $md) {
			display: block;
			font-size: 18px;
		}

		@media (max-width: $sm) {
			font-size: 16px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&-container {
			flex: 1;
			padding: 2.5rem 3rem 3rem;
			box-sizing: border-box;
			min-height: 300px;

			@media (max-width: $md) {
				padding: 2rem;
				min-height: 0;
			}

			@media (max-width: $sm) {
				padding: 1rem 1rem 1rem;
			}
		}

		&-name {
			font-family: $Lora;
			font-weight: 700;
			font-size: 1.777em;
			color: #333;

			@media (max-width: $lg) {
				font-size: 1.563em;
			}

			@media (max-width: $sm) {
				font-size: 1.44em;
			}

			@media (max-width: $xs) {
				font-size: 1.266em;
			}

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					color: $purple;
				}
			}
		}

		&-categories {
			margin-top: 1em;
			font-weight: 700;
			color: #999;
			font-size: 0.75em;

			@media (max-width: $lg) {
				font-size: 0.8em;
			}

			@media (max-width: $sm) {
				font-size: 0.833em;
			}

			@media (max-width: $xs) {
				font-size: 0.889em;
			}

			li {
				display: inline-block;

				@media (max-width: 420px) {
					display: table;
					margin-top: 4px;
				}

				&:nth-last-child(n+2) {
					@media (min-width: 420px) {
						&:after {
							content: " |";
							margin: 0 5px;
						}
					}
				}
			}

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&-description {
			margin-top: 1.3em;
			color: #333;
			line-height: 1.45;
			font-size: 16px;

			@media (max-width: $sm) {
				font-size: 15px;
				line-height: 1.35;
				margin-top: 1rem;
			}

			& > * {
				margin-top: 11px;
			}

			li {
				display: inline;
			}

			ul {
				li {
					&:before {
						content: "\2022";
						color: $lightpurple;
						margin-right: 0.5em;
					}
				}
			}

			&-conditions {
				@media (max-width: $xs) {
					display: none;
				}
			}
		}

		&-meta {
			flex-shrink: 0;
			position: relative;
			min-width: 11rem;
			box-sizing: border-box;
			padding: 2rem;
			padding-right: 1.5rem;
			border-radius: 0 5px 5px 0;
			background-image: linear-gradient(-50deg, $green -20%, $purple 70%);
			font-size: 16px;

			@media (max-width: $md) {
				padding: 1.5rem 2rem;
				min-width: 0;
				border-radius: 0 0 5px 5px;
			}

			@media (max-width: $sm) {
				padding: 1rem;
			}

			@media (max-width: $xs) {
				font-size: 0.889em;
				padding: 0.4rem 1rem 0.6rem;
			}

			& > * {
				vertical-align: middle;
			}

			.date {
				line-height: 23px;
				font-weight: 700;
				margin-bottom: 2rem;

				@media (max-width: $md) {
					display: inline-block;
					margin-bottom: 0;
					margin-right: 2rem;
				}

				@media (max-width: $xs) {
					display: block;
					margin-bottom: 0.2em;
				}

				span,
				time {
					display: block;

					@media (max-width: $md) {
						display: inline;
					}
				}

				span {
					color: rgba(#fff, 0.67);
				}

				time {
					color: #fff;
					font-size: 19px;

					@media (max-width: $sm) {
						font-size: 1.125em;
					}
				}
			}

			.location {
				color: rgba(#fff, 0.75);
				font-weight: 700;

				@media (max-width: $md) {
					display: inline-block;
				}

				i {
					margin-right: 0.25em;
					color: #fff;
					font-size: 18px;
					position: relative;
					top: -2px;

					@media (max-width: $md) {
						font-size: 16px;
					}

					@media (max-width: $xs) {
						font-size: 14px;
						display: none;
					}
				}
			}

			.learn_more {
				position: absolute;
				bottom: 45px;
				left: 0;
				right: 0;
				text-decoration: none;
				color: #fff;
				text-align: center;
				display: block;
				font-size: 11px;
				font-weight: 700;

				@media (max-width: $md) {
					display: inline-block;
					position: relative;
					bottom: 0;
					float: right;
					font-size: 18px;
					line-height: 25px;
				}

				@media (max-width: $sm) {
					font-size: 16px;
				}

				@media (max-width: $xs) {
					float: none;
					position: absolute;
					left: auto;
					bottom: auto;
					right: 1rem;
					top: 50%;
					transform: translate(0, -50%);
				}

				&:hover {
					span {
						text-decoration: underline;
					}
				}

				span {
					@media (max-width: 360px) {
						display: none;
					}
				}

				img {
					display: block;
					margin: 0 auto 9px;

					@media (max-width: $md) {
						height: 23px;
						width: 23px;
						float: right;
						margin: 0;
						position: relative;
						//bottom: -1px;
						margin-left: 0.5em;
						display: none;
					}
				}

				i {
					display: none;

					@media (max-width: $md) {
						display: inline;
						font-size: 24px;
						margin-left: 0.5rem;
						vertical-align: middle;
						position: relative;
						top: -2px;
					}

					@media (max-width: $sm) {
						font-size: 20px;
						margin-left: 5px;
						top: -1px;
					}

					@media (max-width: 360px) {
						font-size: 24px;
						margin: 0;
						top: 0;
					}
				}
			}
		}
	}
}

.vacancies_single {
	&-categories {
		font-family: $Lato;
		font-weight: 700;
		font-size: 1em;
		margin-top: 0.75em;
		color: #999;

		@media (max-width: $sm) {
			margin-bottom: 1.414em;
		}

		li {
			display: inline-block;

			@media (min-width: $xs+1) {
				&:nth-last-child(n+2) {
					&:after {
						content: " |";
						margin: 0 5px;
					}
				}
			}

			@media (max-width: $xs) {
				display: block;
				margin-top: 0.25em;
			}
		}

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&-description {
		& > *:last-child {
			margin-bottom: 0;
		}
	}

	.text {
		.vacancies_single-col {
			@media (max-width: $lg) {
				font-size: 18px;
			}

			@media (max-width: $md) {
				font-size: 16px;
			}
		}
	}

	&-container {
		@include clearfix;
	}

	&-col {
		width: calc(50% - 2.5rem);
		float: left;
		box-sizing: border-box;

		@media (max-width: $lg) {
			width: calc(50% - 1rem);
		}

		@media (max-width: $sm) {
			width: 100%;
			padding-right: 0;
		}

		&:nth-child(2n) {
			float: right;
		}

		&:nth-child(2n + 3) {
			clear: both;
		}

		h2 {
			margin-bottom: 0.75em;
			font-size: 1.777em;

			@media (max-width: $lg) {
				font-size: 1.563em;
			}

			@media (max-width: $sm) {
				font-size: 1.44em;
			}

			@media (max-width: $xs) {
				font-size: 1.266em;
			}
		}

		ol,
		ul {
			li {
				margin: 0.75em 0;
				padding-left: 1.5rem;
				line-height: 1.35;

				&:last-child {
					margin-bottom: 0;
				}

				&:before {
					left: 0;
				}
			}
		}
	}

	&-contact {
		&-item {
			display: inline-block;
			vertical-align: top;

			@media (max-width: 360px) {
				display: block;
			}

			&:first-child {
				padding-right: 80px;

				@media (max-width: $lg) {
					padding-right: 60px;
				}

				@media (max-width: $md) {
					padding-right: 50px;
				}

				@media (max-width: $xs) {
					padding-right: 36px;
				}

				@media (max-width: 360px) {
					padding-right: 0;
				}
			}
		}

		h3 {
			font-size: 1em;
			margin: 0 0 0.5em;

			@media (max-width: 360px) {
				margin-top: 1.414em;
			}
		}

		ul,
		ol {
			li {
				padding-left: 0;

				@media (min-width: $lg+1) {
					font-size: 18px;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:before {
					display: none;
				}
			}
		}

		.recruiter {
			width: 100%;

			h3 {
				margin-top: 1.414em;
			}

			&-name {
				font-weight: 700;
				color: $green;
				margin-bottom: 0;
			}
		}

		.btn {
			margin-top: 1.414em;
			color: #fff;
			font-size: 1em;
			padding: 1rem 3rem;

			@media (min-width: $lg+1) {
				font-size: 20px;
			}
		}
	}
}
