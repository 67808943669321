@import 'base/reset';
@import 'base/typography';
@import 'base/variables';

@import 'layout/common';
@import 'layout/elements';
@import 'layout/text';
@import 'layout/menu';
@import 'layout/footer';


@import 'pages/home';
@import 'pages/vacancies';
@import 'pages/contact';
@import 'pages/news';
@import 'pages/services';
@import 'pages/about';
