.footer {
	padding: 3rem 0;
	background-image: linear-gradient(50deg, $purple 35%, $green 95%);
	color: #fff;
	line-height: 1.6;

	a {
		color: inherit;

		&:hover {
			text-decoration: none;
		}
	}

	&-container {
		@include clearfix;
	}

	&-col {
		width: 33.333%;
		box-sizing: border-box;
		float: left;

		@media (max-width: $md) {
			width: 100%;
			float: none;
			@include clearfix;
		}

		@media (max-width: $xs) {
			margin-bottom: 0;
		}

		&:nth-child(n+2) {
			@media (min-width: $md+1) {
				padding-left: 1rem;
			}
		}
	}

	&-logo {
		margin-bottom: 3rem;
		display: block;

		@media (max-width: $md) {
			margin-bottom: 2rem;
		}

		img {
			display: block;
			height: 3rem;

			@media (max-width: $lg) {
				height: 2.75rem;
			}
		}
	}

	&-block {
		@media (max-width: $md) {
			margin-bottom: 2rem;
			width: 50%;
			float: left;
		}

		@media (max-width: $xs) {
			width: 100%;
			float: none;
		}

		&:nth-last-child(n+2) {
			@media (min-width: $md+1) {
				margin-bottom: 3rem;
			}
		}
	}

	&-header {
		font-family: $Lora;
		font-weight: 700;
		font-size: 19px;
	}

	&-text {
		margin-top: 11px;
		color: rgba(#fff, 0.67);

		li {
			&:nth-child(n+2) {
				margin-top: 11px;
			}
		}

		&.phones {
			a {
				text-decoration: none;
			}
		}
	}

	&-social {
		margin-top: 11px;
		display: flex;
		align-items: center;

		li {
			display: inline-block;
			margin-right: 20px;

			a {
				text-decoration: none;
				opacity: 0.67;

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	&-bottom_line {
		width: 100%;
		@include clearfix;
		margin-top: 3rem;
		color: rgba(#fff, 0.5);

		@media (max-width: $md) {
			margin-top: 2rem;
		}
	}

	&-copy {
		float: left;
		display: inline-block;

		@media (max-width: $xs) {
			display: block;
			float: none;
		}
	}

	&-developer {
		float: right;
		text-align: right;
		display: inline-block;

		@media (max-width: $xs) {
			float: none;
			text-align: left;
			margin-top: 1rem;
			display: block;
		}
	}
}
