.btn {
	background:
		linear-gradient(-180deg, rgba(#fff, 0.2), rgba(#000, 0.2)),
		linear-gradient(-60deg, $green 40%, $purple 220%);
	text-align: center;
	border-radius: 10px;
	display: inline-block;
	text-decoration: none;
	color: rgba(#fff, 0.95);
	font-weight: 700;
	font-size: 23px;
	line-height: 1.25;
	letter-spacing: 0.015em;
	padding: 0.75em 1.5em;
	margin: 0;
	border: none;
	transition: 0.1s ease;
	cursor: pointer;
	box-shadow:
		0 4px 10px 0 rgba($lightpurple, 0.4),
		0 2px 4px 0 rgba(#000, 0.5),
		inset 0 -1px 3px 0 rgba(#000, 0.3);

	&:hover,
	&:focus {
		box-shadow:
			0 4px 10px 0 rgba($lightpurple, 0.5),
			2px 4px 7px 0 rgba(#000, 0.45),
			inset 0 -1px 3px 0 rgba(#000, 0.4);
	}

	&:active,
	&.loading {
		transition: none;
		outline: none;
		box-shadow:
			0 4px 10px 0 rgba($lightpurple, 0.5),
			0 1px 3px 0 rgba(#000, 0.5),
			inset 0 2px 4px 0 rgba(#000, 0.3);
	}

	&.loading {

		img,
		svg {
			height: 10px;
		}

		&:hover:disabled {
			box-shadow:
				0 4px 10px 0 rgba($lightpurple, 0.5),
				0 1px 3px 0 rgba(#000, 0.5),
				inset 0 2px 4px 0 rgba(#000, 0.3);
		}
	}

	&.success {
		i {
			font-size: 18px;
			margin-left: 0.25em;
		}
	}

	&:disabled {
		cursor: default;

		&,
		&:hover {
			background:
				linear-gradient(-180deg, rgba(#fff, 0.2), rgba(#000, 0.2)),
				linear-gradient(-60deg, rgba($green, 0.75) 40%, rgba($purple, 0.75) 220%);
		}

		&:hover {
			box-shadow:
				0 4px 10px 0 rgba($lightpurple, 0.4),
				0 2px 4px 0 rgba(#000, 0.5),
				inset 0 -1px 3px 0 rgba(#000, 0.3);
		}
	}

	&--emphasize {
		box-shadow:
			0 4px 50px 0 rgba($lightpurple, 0.4),
			0 2px 4px 0 rgba(#000, 0.5),
			inset 0 -1px 3px 0 rgba(#000, 0.3);

		&:hover,
		&:focus {
			box-shadow:
				0 4px 50px 0 rgba($lightpurple, 0.5),
				2px 4px 7px 0 rgba(#000, 0.45),
				inset 0 -1px 3px 0 rgba(#000, 0.4);
		}

		&:active {
			box-shadow:
				0 4px 40px 0 rgba($lightpurple, 0.5),
				0 1px 3px 0 rgba(#000, 0.5),
				inset 0 2px 4px 0 rgba(#000, 0.3);
		}
	}
}

.form {
	color: #222;

	&-fieldset {
		font-size: 20px;
		margin-top: (2.369em * 1.414);

		@media (max-width: $lg) {
			margin-top: (1.953em * 1.414);
		}

		@media (max-width: $md) {
			font-size: 18px;
		}

		@media (max-width: $sm) {
			font-size: 16px;
			margin-top: (1.728em * 1.414);
		}

		@media (max-width: $xs) {
			margin-top: (1.424em * 1.414);
		}

		legend {
			font-family: $Lora;
			font-weight: 700;
			margin: 0 0 0.4em;
			color: #333;
			font-size: 2.369em;

			@media (max-width: $lg) {
				font-size: 1.953em;
			}

			@media (max-width: $sm) {
				font-size: 1.728em;
				margin-bottom: 0;
			}

			@media (max-width: $xs) {
				font-size: 1.424em;
			}
		}
	}

	&-grid {
		@include clearfix;
		margin: 0 -1rem;

		.form-input {
			padding-left: 1rem;
			padding-right: 1rem;
			width: 50%;
			box-sizing: border-box;
			float: left;

			@media (max-width: $sm) {
				width: 100%;
			}

			.label {
				padding-left: 1rem;
			}

			.error-message {
				padding-right: 1rem;
			}
		}
	}

	&-input {
		display: block;
		position: relative;
		padding-top: 1.5rem;
		margin-top: 2rem;
		font-size: 16px;

		@media (max-width: $md) {
			margin-top: 1.75rem;
		}

		@media (max-width: $sm) {
			margin-top: 1.5rem;
		}

		&.required {
			.label {
				&:after {
					font-size: 19px;
					margin-left: 3px;
					content: "*";
					color: $lightpurple;
				}
			}
		}

		&.error {
			.label {
				right: 50%;
			}

			.error-message {
				display: block;
			}

			input,
			textarea {
				border-color: $red;
			}
		}

		.label {
			display: block;
			font-weight: 700;
			color: #444;
			white-space: nowrap;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			overflow: hidden;
		}

		.error-message {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			left: 50%;
			color: $red;
			text-align: right;
		}

		input,
		textarea {
			display: block;
			width: 100%;
			background: #fff;
			border: 2px solid $lightpurple;
			box-shadow:
				0 1px 3px 0 rgba($lightpurple, 0.5),
				//inset 0 1px 7px 0 rgba(#000, 0.5),
				inset 0 1px 6px 0 rgba($lightpurple, 0.4);
			border-radius: 8px;
			font-size: 19px;
			line-height: 28px;
			padding: 1rem 1.25em;
			margin: 0;
			box-sizing: border-box;

			@media (max-width: $md) {
				font-size: 16px;
				padding: 0.75rem 1rem;
			}

			@media (max-width: $sm) {
				//border-width: 1px;
				padding: 0.5rem 1rem;
			}

			&::placeholder {
				color: #aaa;
			}

			&:focus {
				border-color: $purple;
				box-shadow:
					0 1px 3px 0 rgba($lightpurple, 0.5),
					inset 0 1px 8px 0 rgba(#000, 0.25),
					inset 0 1px 6px 0 rgba($lightpurple, 0.6);

				& + .label {
					color: #000;
				}
			}
		}

		textarea {
			resize: none;
			height: 10rem;
		}
	}

	&-file {
		display: block;
		margin-top: 2.5rem;

		@media (max-width: $sm) {
			margin-top: 1.5rem;
		}

		&.required {
			.label {
				&:after {
					margin-left: 3px;
					content: "*";
					color: $lightpurple;
				}
			}
		}

		input {
			position: fixed;
			left: -100vw;
			height: 1px;
			width: 1px;
			border: none;
			box-shadow: none;
			background: none;
		}

		.label {
			font-size: 19px;
			font-weight: 700;
			cursor: pointer;
			color: #444;

			@media (max-width: $sm) {
				font-size: 16px;
			}

			&:hover {
				color: #000;
			}

			i {
				color: $lightpurple;
				font-size: 2rem;
				margin-right: 0.5rem;
			}
		}

		&-added {
			& > div {
				color: $lightpurple;
				margin-top: 0.5em;

				&:first-child {
					margin-top: 1rem;
				}
			}
		}

		.error-message {
			display: none;
			position: absolute;
			top: -2px;
			left: 40px;
			font-size: 12px;
			color: $red;
		}

		&.error {
			position: relative;

			.error-message {
				display: inline-block;
			}

			.label {
				i {
					color: $red;
				}

				&:after {
					color: $red;
				}
			}
		}
	}

	&-checkbox {
		display: block;
		cursor: pointer;
		@include no_select;

		input {
			position: absolute;
			left: -100vw;

			& + span {
				pointer-events: none;

				&:before {
					content: "\f00c";
					@include fontawesome;
					display: inline-block;
					height: 24px;
					width: 24px;
					line-height: 20px;
					color: #fff;
					box-sizing: border-box;
					border: 2px solid $lightpurple;
					text-align: center;
					background: #fff;
					box-shadow: 0 1px 3px 0 rgba($lightpurple, 0.5);
					border-radius: 4px;
					margin-right: 12px;

					@media (max-width: $md) {
						height: 22px;
						width: 22px;
						line-height: 18px;
						position: relative;
						bottom: 1px;
					}

					@media (max-width: $sm) {
						margin-right: 10px;
					}

					@media (max-width: $xs) {
						height: 18px;
						width: 18px;
						line-height: 14px;
						font-size: 12px;
						vertical-align: bottom;
						margin-right: 6px;
						position: relative;
						bottom: -1px;
					}
				}
			}

			&:checked + span {
				font-weight: 700;

				&:before {
					border-width: 0;
					line-height: 24px;
					background-image:
						linear-gradient(-180deg, rgba(#fff, 0.25) 0%, rgba(#000, 0.25) 100%),
						linear-gradient(-26deg, $green 25%, $purple 115%);
					box-shadow: none;

					@media (max-width: $md) {
						line-height: 22px;
					}

					@media (max-width: $xs) {
						line-height: 18px;
					}
				}
			}
		}

		.select_all {
			& + span {
				font-weight: 700;
				color: $purple;
			}
		}
	}


	.btn-wrapper {
		position: relative;
		margin-top: 2em;

		.error-message {
			color: $red;
			margin-bottom: 1em;
		}
	}

	.btn {
		color: #fff;
		padding: 1.125rem 3rem;
		font-size: 20px;
		min-width: 280px;

		@media (max-width: $md) {
			font-size: 18px;
			padding: 1em 3rem;
		}

		@media (max-width: $sm) {
			min-width: 260px;
		}

	}
}

.specializations {
	font-size: 19px;
	line-height: 1.4;

	@media (max-width: $sm) {
		font-size: 16px;
	}

	ul {
		@include clearfix;

		li {
			width: 50%;
			float: left;
			white-space: nowrap;
			padding: 0;
			margin: 0;
			text-overflow: ellipsis;
			overflow: hidden;

			&:nth-last-child(n+3) {
				margin-bottom: 11px;
			}

			@media (max-width: $xs) {
				float: none;
				width: 100%;
				white-space: normal;

				&:nth-last-child(n+2) {
					margin-bottom: 8px;
				}
			}

			&:before {
				display: none;
			}

			i {
				color: $lightpurple;
				margin-right: 5px;
				width: 30px;
				display: inline-block;
				text-align: center;

				@media (max-width: $sm) {
					font-size: 14px;
					margin-right: 2px;
					width: 20px
				}
			}
		}
	}
}

.pagination {
	margin: 4rem auto 0;
	display: table;
	border-collapse: separate;
	border-spacing: 1.5rem 0;

	@media (max-width: $md) {
		border-spacing: 1rem 0;
	}

	@media (max-width: $xs) {
		margin-top: 3rem;
		border-spacing: 0.5rem 0;
	}

	& > * {
		display: table-cell;
		width: 45px;
		height: 45px;
		box-sizing: border-box;
		vertical-align: middle;
		text-align: center;
		text-decoration: none;
		padding-top: 1px;
		line-height: 1;

		@media (max-width: $sm) {
			width: 42px;
			height: 42px;
		}

		@media (max-width: $xs) {
			width: 38px;
			height: 38px;
		}

		@media (max-width: 360px) {
			width: 35px;
			height: 35px;
		}
	}

	&-item {
		background: #fff;
		border: 2px solid $lightpurple;
		box-shadow: 0 1px 4px 0 rgba($lightpurple, 0.5);
		border-radius: 6px;
		color: #333;
		font-weight: 700;
		font-size: 19px;
		transition: 0.1s ease;
		@include no_select;

		@media (max-width: $xs) {
			font-size: 17px;
		}

		@media (max-width: 360px) {
			font-size: 16px;
		}

		&.current {
			background:
				linear-gradient(-180deg, rgba(#fff, 0.25) 0%, rgba(#000, 0.25) 100%),
				linear-gradient(-37deg, $green 0%, $purple 100%);
			color: #fff;
			border: none;
			cursor: default;
		}

		&:not(.current) {
			&:hover {
				background-color: rgba($green, 0.05);
			}
		}
	}

	&-arrow {
		font-size: 32px;
		color: $purple;
		transition: 0.1s ease;

		@media (max-width: $sm) {
			font-size: 30px;
		}

		@media (max-width: $xs) {
			font-size: 24px;
		}

		@media (max-width: 360px) {
			font-size: 22px;
		}

		&.disabled {
			color: rgba($purple, 0.4);
		}

		&:not(.disabled) {
			&:hover {
				color: $lightpurple;
			}
		}
	}
}
