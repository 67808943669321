.home {
	&-header {
		font-size: 20px;
		margin-top: 8rem;
		margin-bottom: 2.369em;

		@media (max-width: $lg) {
			margin-bottom: 1.953em;
		}

		@media (max-width: $md) {
			font-size: 18px;
			margin-top: 6rem;
		}

		@media (max-width: $sm) {
			margin-top: 4rem;
			margin-bottom: 1.728em;
			font-size: 16px;
		}

		@media (max-width: $xs) {
			margin-top: 3rem;
			margin-bottom: 1.424em;
		}

		&-text {
			font-family: $Lora;
			font-weight: 700;
			font-size: 2.369em;
			color: #333;
			display: inline-block;
			margin-right: 0.66em;

			@media (max-width: $lg) {
				font-size: 1.953em;
			}

			@media (max-width: $sm) {
				font-size: 1.728em;
				margin-right: 0;
				display: block;
			}

			@media (max-width: $xs) {
				font-size: 1.424em;
			}
		}

		&-link {
			font-weight: 700;
			color: $green;
			position: relative;
			top: -2px;
			display: inline-block;
			font-size: 16px;

			@media (max-width: $sm) {
				top: 0;
				margin-top: 0.5rem;
				font-size: 14px;
			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	&-advantages {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center 40%;
		background-color: #666;
		position: relative;
		height: 600px;

		@media (max-width: $lg) {
			height: 0;
			padding-bottom: 45%;
		}

		@media (max-width: $md) {
			padding-bottom: 50%;
		}

		@media (max-width: $sm) {
			padding-bottom: 55%;
		}

		@media (max-width: $xs) {
			padding-bottom: 60%;
		}

		&-slider {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			font-size: 6rem;
			line-height: 0;
			background: rgba(0, 0, 0, 0.2);

			&.slick-slider {
				position: absolute;
			}

			@media (max-width: $lg) {
				font-size: 4.5rem;
			}

			@media (max-width: $md) {
				font-size: 4rem;
			}

			@media (max-width: $sm) {
				font-size: 3.5rem;
			}

			@media (max-width: $xs) {
				font-size: 2.75rem;
			}

			@media (max-width: 350px) {
				font-size: 2.5rem;
			}

			.slick-list,
			.slick-track,
			.slick-track .container {
				height: 100%;
			}

			.slick-dots {
				position: absolute;
				top: 0.66em;
				left: 0;
				right: 0;
				@extend .container;
				pointer-events: none;
				height: auto;
				line-height: 0;

				@media (max-width: $lg) {
					//top: 3rem;
				}

				@media (max-width: $sm) {
					top: 0.5em;
				}

				@media (max-width: $xs) {
					right: 0.5rem;
					text-align: right;
				}

				li {
					pointer-events: all;
					display: inline-block;
					margin-right: 20px;
					font-size: 0;
					line-height: 0;
					vertical-align: top;

					@media (max-width: $sm) {
						margin-right: 1rem;
					}

					@media (max-width: $xs) {
						margin-right: 0;
						margin-left: 1rem;
					}

					button {
						font-size: 0;
						line-height: 0;
						box-sizing: border-box;
						padding: 0;
						margin: 0;
						border: none;
						border-radius: 50%;
						height: 20px;
						width: 20px;
						background-color: #fff;
						outline: none;
						cursor: pointer;

						@media (max-width: $sm) {
							height: 1rem;
							width: 1rem;
						}
					}

					&.slick-active {
						button {
							background:
								linear-gradient(-60deg, $green 30%, $purple 150%);
						}
					}
				}
			}
		}

		&-item {
			height: 100%;
			max-height: 100%;
			box-sizing: border-box;
			position: relative;
		}

		&-text {
			position: absolute;
			bottom: 0.66em;
			left: 0;
			font-family: $Lora;
			color: #fff;
			line-height: 1;
			text-shadow: 1px 3px 5px rgba($lightpurple, 0.6);

			@media (max-width: $sm) {
				bottom: 0.5em;
				left: 0.5rem;
			}

			@media (max-width: $xs) {
				left: 0;
			}
		}
	}

	&-action {
		font-size: 20px;
		padding-top: 5rem;

		@media (max-width: $lg) {
			padding-top: 4.5rem;
		}

		@media (max-width: $md) {
			padding-top: 4rem;
			font-size: 18px;
		}

		@media (max-width: $sm) {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			font-size: 16px;
		}

		.container {
			@include clearfix;
		}

		&-item {
			width: 50%;
			float: left;
			text-align: center;

			@media (max-width: $sm) {
				width: 400px;
				max-width: 100%;
				margin: 0 auto;
				float: none;
				text-align: left;
				position: relative;
				padding-left: 110px;
				box-sizing: border-box;
				margin-top: 4rem;

				&:first-child {
					left: -10%;
				}

				&:last-child {
					right: -10%;
				}
			}

			@media (max-width: $xs) {
				//width: 100%;
				display: table;
				width: auto;
				padding-left: 70px;
				margin-top: 2rem;

				&:first-child {
					left: -7.5%;
				}

				&:last-child {
					right: -7.5%;
				}
			}

			@media (max-width: 350px) {
				width: 100%;

				&:first-child {
					left: 0;
				}

				&:last-child {
					right: 0;
				}
			}

			&-question {
				font-family: $Lora;
				font-weight: 700;
				font-size: 1.777em;
				color: #333;
				white-space: nowrap;

				@media (max-width: $lg) {
					font-size: 1.563em;
				}

				@media (max-width: $sm) {
					font-size: 1.44em;
				}

				@media (max-width: $xs) {
					font-size: 1.266em;
					white-space: normal;
				}
			}

			img {
				display: block;
				margin: auto;
				margin-top: 2rem;
				height: 90px;

				@media (max-width: $md) {
					height: 70px;
					margin-top: 1.75rem;
				}

				@media (max-width: $sm) {
					margin: 0;
					position: absolute;
					top: 0;
					bottom: 0;
					margin-top: auto;
					margin-bottom: auto;
					left: 0;
					width: 70px;
					height: auto;
				}

				@media (max-width: $xs) {
					width: 50px;
				}
			}

			.btn {
				margin-top: 2rem;
				min-width: 320px;
				box-sizing: border-box;

				@media (max-width: $md) {
					font-size: 19px;
					min-width: 250px;
					margin-top: 1.75rem;
				}

				@media (max-width: $xs) {
					min-width: 0;
					margin-top: 1rem;
					width: 100%;
					max-width: 220px;
					font-size: 16px;
				}
			}
		}
	}

	&-clients {
		overflow: hidden;

		&-container {
			@include clearfix;
			margin: -20px -1rem 0;

			@media (max-width: $sm) {
				margin-left: -0.5rem;
				margin-right: -0.5rem;
			}
		}

		&-item {
			opacity: 0.8;
			width: (100% / 6);
			float: left;
			padding: 0.25rem 1rem;
			box-sizing: border-box;
			height: 100px;
			margin-top: 20px;

			@media (max-width: $md) {
				width: 25%;
				height: 70px;
				padding: 0.25rem 1rem;
			}

			@media (max-width: $sm) {
				height: 60px;
				padding: 0.25rem 0.5rem;
			}

			@media (max-width: $xs) {
				width: 33.333%;
				height: 50px;
				padding: 0.125rem 0.5rem;

			}

			img {
				height: 100%;
				width: 100%;
				filter: grayscale(100%);
			}
		}
	}
}
