html {
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.disable_scroll {
		overflow-y: scroll;
		height: 100%;

		body {
			overflow-y: hidden;
			height: 100%;
		}
	}
}

body {
	font-family: $Lato;
	background-color: #fcfcfc;
}

.wrapper {
	padding-bottom: 6rem;
	padding-top: 4rem;

	@media (max-width: $lg) {
		padding-bottom: 6rem;
	}

	@media (max-width: $md) {
		padding-top: 3.5rem;
	}

	@media (max-width: $sm) {
		padding-top: 0;
		padding-bottom: 4rem;
	}
}

.double_menu ~ .wrapper {
	padding-top: 8rem;

	@media (max-width: $md) {
		padding-top: 7rem;
	}

	@media (max-width: $sm) {
		padding-top: 0;
	}
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

input,
textarea,
button {
	font-family: $Lato;
	-webkit-appearance: none;
	border-radius: 0;
	box-shadow: none;
	border: none;
	outline: none;
}

button {
	&::-moz-focus-inner {
		border: 0;
	}
}

.container,
.container--text {
	margin: 0 auto;
	padding: 0 3rem;
	box-sizing: border-box;

	@media (max-width: $md) {
		padding: 0 2rem;
	}

	@media (max-width: $sm) {
		padding: 0 1.5rem;
	}

	@media (max-width: $xs) {
		padding: 0 1rem;
	}
}

.container {
	max-width: 1200px + 6 * 16px;
}

.container--text {
	max-width: 990px + 6 * 16px;
}
