.team {
	&-item {
		@include clearfix;
		padding: 1.77em 0;
		border-bottom: 1px solid rgba($lightpurple, 0.25);

		.photo {
			float: left;
			height: 300px;
			width: 300px;
			box-sizing: border-box;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border: 1px solid $purple;
			box-shadow: 0 1px 4px 0 rgba($lightpurple, 0.5);
			border-radius: 8px;

			@media (max-width: $md) {
				width: 250px;
				height: 250px;
			}

			@media (max-width: $sm) {
				width: 275px;
				height: 275px;
				margin: 0 auto;
				float: none;
			}
		}

		.person {
			width: calc(100% - 300px);
			box-sizing: border-box;
			padding-left: 3rem;
			float: right;

			@media (max-width: $md) {
				width: calc(100% - 250px);
			}

			@media (max-width: $sm) {
				width: 100%;
				float: none;
				padding: 0;
				margin-top: 1rem;
			}

			&-name {
				font-weight: 700;
				font-family: $Lora;
				font-size: 1.777em;
				color: #333;

				@media (max-width: $lg) {
					font-size: 1.563em;
				}

				@media (max-width: $sm) {
					font-size: 1.44em;
				}

				@media (max-width: $xs) {
					font-size: 1.266em;
				}
			}

			&-position {
				font-weight: 700;
				color: #999;
				font-size: 0.75em;

				@media (max-width: $lg) {
					font-size: 0.8em;
				}

				@media (max-width: $sm) {
					font-size: 0.833em;
				}

				@media (max-width: $xs) {
					font-size: 0.889em;
				}
			}

			&-bio {
				font-size: 16px;
				margin-top: 1.3em;
				& > *:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.clients {
	@include clearfix;
	padding-top: 0.5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media (max-width: $sm) {
		display: block;
	}

	&-item {
		width: calc(50% - 1rem);
		height: 70px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba($lightpurple, 0.25);
		position: relative;
		display: table;
		table-layout: fixed;

		@media (max-width: $sm) {
			width: 100%;
			float: none;
		}

		&-img {
			display: table-cell;
			vertical-align: middle;
			width: 80px;
			padding-left: 0;
			box-sizing: border-box;
			height: 70px;
			position: relative;

			img {
				display: block;
				position: absolute;
				top: 50%;
				transform: translate(50%, -50%);
				right: 50%;
				max-width: 100%;
				max-height: 100%;
				padding: 0.5rem;
				box-sizing: border-box;
			}
		}

		&-name {
			display: table-cell;
			vertical-align: middle;
			box-sizing: border-box;
			line-height: 1.2;
			width: 100%;
			padding: 0.5rem;
			padding-right: 1em;
		}
	}
}
