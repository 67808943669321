// Media query breakpoints
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 576px;



// Colors
$lightpurple: #8E00AB;
$purple: #52135F;
$green: #27926F;
$red: #D33F49;



// Fonts
$sans-serif: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$serif: 'Times New Roman', 'Times', serif;

$Lato: 'Lato', $sans-serif;
$Lora: 'Lora', $serif;



// Mixins
@mixin mobile_scroll {
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

@mixin no_scrollBar {
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin no_select {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	outline: none;
}

@mixin animation_hack {
	transform: translateZ(0);
	backface-visibility: hidden;
}

@mixin fontawesome {
	font-family: "Font Awesome 5 Free";
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: 900;
}

@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
