.services {
	&-navigation {
		@media (min-width: $sm+1) {
			padding-top: 1em;
		}

		li {
			margin-top: 1em;
			font-size: 1.333em;

			@media (max-width: $lg) {
				font-size: 1.25em;
			}

			@media (max-width: $sm) {
				font-size: 1.2em;
			}

			@media (max-width: $xs) {
				font-size: 1.125em;
			}
		}
	}
}
