.menu {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;

	@media (max-width: $sm) {
		position: relative;
		padding-top: 16px * 3.5;
	}

	@media (max-width: $xs) {
		padding-top: 16px * 3;
	}

	&-container {
		@include clearfix;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.top_level {
		background-color: #000;
		background-image: linear-gradient(130deg, $purple 20%, $green 90%);
		color: #fff;
		position: relative;
		z-index: 2;
		box-shadow: 0 0 5px 0 #000;

		@media (max-width: $sm) {
			position: fixed;
			z-index: 100;
			top: 0;
			left: 0;
			right: 0;
		}

		.menu-nav {
			float: right;

			@media (max-width: 1140px) {
				display: none;
				float: none;
				position: absolute;
				top: calc(100% + 3px);
				right: 3px;
				padding: 0.5rem 1.5rem;
				box-shadow: 0 0 5px 0 rgba($purple, 0.5);
				min-width: 220px;
				box-sizing: border-box;
				border-radius: 6px;

				background-color: #fff;
				background-image: linear-gradient(-40deg, rgba($purple, 0.5) 0%, rgba($green, 0.5) 60%);


				@supports (backdrop-filter: blur(5px)) {
					backdrop-filter: blur(5px);
					background-color: rgba(#fff, 0.9);
				}

				&.open {
					display: block;
				}
			}

			li {
				margin-left: 2.5rem;

				@media (max-width: 1140px) {
					display: block;
					margin: 0;
				}

				@media (max-width: $md) {
					padding: 0.5rem 0;

				}

				&:not(.active) {
					a {
						@media (max-width: 1140px) {
							color: #222;
						}

						&:hover {
							text-decoration: underline;
						}
					}
				}

				&.active {
					@media (max-width: 1140px) {
						background-color: rgba($purple, 0.8);
						//background-color: $purple;
						padding-left: 1rem;
						padding-right: 1rem;
						margin-left: -1rem;
						margin-right: -1rem;
						border-radius: 6px;
					}

					a {
						box-shadow: 0 2px 0 #fff;

						@media (max-width: 1140px) {
							box-shadow: none;
							color: #fff;
						}
					}
				}
			}
		}
	}

	&-logo {
		float: left;
		height: 3rem;
		text-decoration: none;
		display: block;
		color: #fff;

		@media (max-width: $md) {
			height: 2.5rem;
		}

		@media (max-width: $xs) {
			height: 2rem;
		}

		img {
			display: block;
			height: 100%;
			box-sizing: border-box;
			@media (min-width: $md+1) {
				padding: 0.125rem 0;
			}
		}
	}

	&-nav {
		font-weight: 700;

		li {
			display: inline-block;
			padding: 0.75rem 0;

			&.active a {
				cursor: default;

				&:hover {
					text-decoration: none;
				}
			}
		}

		a {
			display: block;
			text-decoration: none;
			color: inherit;
			line-height: 1.5rem;
		}
	}

	&-burger {
		display: none;
		float: right;
		padding: 0.5rem;
		cursor: pointer;
		position: relative;
		right: -0.5rem;
		@include no_select;

		@media (max-width: 1140px) {
			display: inline-block;
		}

		@media (max-width: $md) {
			padding: 0.25rem;
			bottom: -1px;
			right: -0.25rem;
		}

		@media (max-width: $xs) {
			padding: 0.125rem;
			right: -0.125rem;
		}

		i {
			font-size: 2rem;
			display: block;
			line-height: 1;
			width: 2rem;
			text-align: center;

			@media (max-width: $xs) {
				font-size: 1.75rem;
			}
		}
	}

	.bottom_level {
		background-color: #fff;
		background-image: linear-gradient(-40deg, rgba($purple, 0.5) 0%, rgba($green, 0.5) 60%);
		position: relative;
		z-index: 1;
		box-shadow: 0 0 5px 0 #000;

		@supports (backdrop-filter: blur(5px)) {
			backdrop-filter: blur(5px);
			background-color: rgba(#fff, 0.9);
		}

		@media (max-width: $sm) {
			box-shadow: none;
		}

		.menu-nav {
			@media (max-width: $sm) and (min-width: $xs+1) {
				padding: 0.5rem 0;
			}
			
			li {
				@media (max-width: $md) {
					padding: 0.5rem 0;
				}

				&:nth-last-child(n+2) {
					margin-right: 3rem;

					@media (max-width: $md) {
						margin-right: 2.5rem;
					}

					@media (max-width: $sm) {
						margin-right: 0;
					}
				}

				@media (max-width: $sm) {
					display: block;
				}

				&.active {
					@media (max-width: $sm) {
						background-color: rgba($green, 0.75);
						padding-left: 1rem;
						padding-right: 1rem;
						margin-left: -1rem;
						margin-right: -1rem;
						border-radius: 6px;
					}

					@media (max-width: $xs) {
						border-radius: 0;
					}

					a {
						color: #000;
						box-shadow: 0 2px 0 $purple;

						@media (max-width: $sm) {
							box-shadow: none;
							color: #fff;
						}
					}
				}
			}

			a {
				color: #333;

				@media (max-width: $sm) {
					color: #222;
				}

				&:hover {
					color: #000;
				}
			}
		}
	}

}
